
import { TableColumn } from '@/models/TableColumn'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/Pagination.vue'

@Component({
  components:{
    Pagination
  }
})
export default class Table extends Vue {
  @Prop({ default: '' })
  emptyMsg!: string;

  @Prop()
  columns!: TableColumn[];

  @Prop({ default: () => ([]) })
  data!: { [key: string]: string | { value: string; sortValue: string}}[];

  @Prop({ default: false, type: Boolean })
  expandable !: boolean;

  selectedAll = false;

  getSelectedAll() {
    this.selectedAll = this.selected.length === this.data.length;
  }

  indexedData: { [key: string]: string | { value: string; sortValue: string}}[] = [];

  selected: number[] = [];

  toggleAll() {
    if (!this.selectedAll){
      this.selected = [];
      for (let i = 0; i < this.data.length; i++) {
        this.selected.push(i);
      }
    }else{
      this.selected = [];
    }
    this.$forceUpdate();
    this.getSelectedAll();
  }

  onSelect() {
    setTimeout(()=>{
      this.getSelectedAll();
    }, 25);
  }

  @Watch('data')
  indexData() {
    this.indexedData = this.data.map((r, index) => (Object.assign({
      index
    }, r)))
  }

  mounted() {
    this.indexData();
  }

  onHeadingClick(c: TableColumn) {
    this.$emit('headingClick', c.key)
  }

}
